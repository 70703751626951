<template>
  <div id="FestivalHomepage" :style="computedPageStyle">
    <QsDbSelector v-if="!hasTag('ED360')" style="grid-column: 1 / -1" />

    <qs-kpi-grid
      title="Votre activité billetterie"
      :qlik-ids="['KTaFf', 'YXxdPC', 'QRNejcq', 'hUek']"
      :preserves-on-destroy="['QRNejcq']"
      tooltip="Quelques indicateurs clef de votre base billetterie."
    />

    <qs-kpi-grid
      title="Votre activité cashless"
      :qlik-ids="['Pc', 'rgvvG', 'sNPmMv', 'WRpjzv']"
      tooltip="Quelques indicateurs clef de votre base cashless."
    />

    <QsCard :qlik-ids="['LrYQyf']" />
    <QsCard :qlik-ids="['KJtRjA']" />

    <card>
      <qs-kpi qlik-id="xEJqgQR" />
    </card>
    <card>
      <qs-kpi qlik-id="RRDcG" />
    </card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";
import QsKpiGrid from "@/components/Qlik/QsKpiGrid";
import QsDbSelector from "@/components/Qlik/QsDbSelector";
import QsCard from "@/components/Qlik/QsCard";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("qlik");

export default {
  name: "FestivalHomepage",
  components: { Card, QsKpi, QsDbSelector, QsKpiGrid, QsCard },
  computed: {
    ...mapGetters(["hasTag"]),
    computedPageStyle() {
      return `grid-template-rows: ${
        this.hasTag("ED360") ? "" : "auto "
      }250px 400px 120px;`;
    }
  }
};
</script>

<style scoped>
#FestivalHomepage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
